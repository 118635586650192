import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ManualChargeConstant } from "../ManualCharge/ManualChargeConstant";

let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_MAN_CHARGE_VESSEL_COL_DEF: any[] = [
    {
        headerName: ManualChargeConstant.Header.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: ManualChargeConstant.Header.SOA,
        field: 'soa',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
    },
    {
        headerName: ManualChargeConstant.Header.VSL_NAME,
        field: 'vesselName',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
    },
    {
        headerName: ManualChargeConstant.Header.VSL_CODE,
        field: 'vesselCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: ManualChargeConstant.Header.VOY_CODE,
        field: 'voyageCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: ManualChargeConstant.Header.ARR_DATETIME,
        field: 'arrDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        dataType: "dateTime"
    },
    {
        headerName: ManualChargeConstant.Header.DEP_DATETIME,
        field: 'depDatetime',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        dataType: "dateTime"
    },
    {
        headerName: ManualChargeConstant.Detail.CHARGE_ON,
        field: 'chargeOnCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ManualChargeConstant.Header.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: ManualChargeConstant.Header.CONS_CODE,
        field: 'consortiumCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: ManualChargeConstant.Header.VSL_TYPE,
        field: 'vesselType',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}