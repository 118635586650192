import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_MAN_CHARGE_VESSEL_COL_DEF, transferRowData } from "presentation/constant/ManualCharge/ManChargeVesselColumnDefinition";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeDetailVesselVM } from "presentation/hook/ManualCharge/useManualChargeDetailVesselVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ManualChargeVesselTablePanel = () => {
    const [manualChargeDtlState] = useManualChargeDetailTracked();
    const manualDetailVesselVM = useManualChargeDetailVesselVM();
    const [anaInfoState] = useANAInfoTracked();
    const { allowUpdate } = anaInfoState;
    const { selectedManualChargeDetailVesselRow } = manualChargeDtlState;
    const gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const messageBarVM = useMessageBarVM();
    const tableId = 'manual-charge-dtl-cntr-records-table';

    const handleApply = useCallback(async () => {
        if (selectedManualChargeDetailVesselRow.length === 0) {
            messageBarVM.showWarining('Please select a record.');
            return;
        }

        setIsLoading(true);
        manualDetailVesselVM.apply(selectedManualChargeDetailVesselRow).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [selectedManualChargeDetailVesselRow, messageBarVM, manualDetailVesselVM]);

    const isDisableApply = () => {
        return selectedManualChargeDetailVesselRow.length === 0;
    }

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        manualDetailVesselVM.updateSelectedManualChargeDetails(selectedRows);
    }, [manualDetailVesselVM])

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        //const columnDefs = (INITIAL_MAN_CHARGE_VESSEL_COL_DEF.slice());


        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }

    })

    const memoManualChargeDetailVesselTable = useMemo(() => {
        return <NbisTable
            id={tableId}
            isNewColumnSetting={true}
            columns={INITIAL_MAN_CHARGE_VESSEL_COL_DEF.slice()}
            data={transferRowData(manualChargeDtlState.manualChargeDetailVessels)}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            isRowHighligted={true}
            selectionMode={false}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(59vh)"
            ref={gridRef}
        />
    }, [manualChargeDtlState.manualChargeDetailVessels, handleSelectionChange])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{<TableWrapper>{memoManualChargeDetailVesselTable}
        {<StyledAction className="tm-animated-wrapper">
            {(allowUpdate) && <HPHButton disabled={isDisableApply()} label={WorkspaceConstant.Common.BUTTON_UPDATE} size={'Small'} theme={'Primary'} onClick={handleApply} />}
        </StyledAction>}
    </TableWrapper>}</>;
}

export default memo(ManualChargeVesselTablePanel);