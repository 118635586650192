import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customEventListener } from "helpers/Events";
import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { ViewAttachmentModal } from "../ConfirmModal/ViewAttachmentModal";
import { ManualChargeHeaderFieldFormPanel } from "./Form/ManualChargeHeaderFieldFormPanel";
import ManualChargeDetailContent from "./ManualChargeDetailContent";
import ManualChargeDetailTitleBar from "./ManualChargeDetailTitleBar";


const ManualChargeDetailMaintenance:React.FC = () => {

    const [ manualChargeDetailState, setManualChargeDetailState ] = useManualChargeDetailTracked();
    const [ manChgHdrState ] = useManualChargeHeaderMaintenanceTracked();
    const manualChargeDetailVM = useManualChargeDetailVM();
    const manualChargeHeaderVM = useManualChargeHeaderMaintenanceVM();
    const { isShowAttachmentModal } = manualChargeDetailState.viewState;
    const [initial, setInitial] = useState<boolean>(false);
    const currentManualChargeHeader = manChgHdrState?.currentManualChargeHeader;

    const initialScreen = useCallback(async() => {
        if (!currentManualChargeHeader) return;
        manualChargeDetailVM.onShowLoading();
        try {
            const results = await Promise.allSettled([
                manualChargeDetailVM.loadDropdownOption(),
                manualChargeDetailVM.onPageInit(currentManualChargeHeader),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                  } else if (index === 1 && result.status === 'rejected') {

                  }
            });
            manualChargeDetailVM.onHideLoading();
            setInitial(false);
        } catch(error) {
        }
    }, [currentManualChargeHeader, manualChargeDetailVM]);

    const initialRedirectScreen = useCallback(async() => {
        const manSysCharge = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)??"") as ManualSystemChargeEntity :
            null;
        if (!manSysCharge) return;
        manualChargeDetailVM.onShowLoading();

        await Promise.allSettled([
            manualChargeDetailVM.loadDropdownOption(),
            manualChargeHeaderVM.onSearchById(manSysCharge.hdrId??0).then(data => {
                if (data) {
                    const manualChargeHeader = data;

                    manualChargeDetailVM.onPageInit(manualChargeHeader);
                    setManualChargeDetailState(prevState => ({
                        ...prevState,
                        currentManualChargeHeader: {
                            ...prevState.currentManualChargeHeader,
                            ...manualChargeHeader
                        },
                        isShowCloseIcon: false
                    }));
                    manualChargeDetailVM.onSearch(manualChargeHeader.id??0).then(data => {
                        manualChargeDetailVM.onHideLoading();
                        setInitial(false);
                    }).catch(error => {                        
                        manualChargeDetailVM.onHideLoading();
                        setInitial(false);
                    }).finally(() => {                        
                        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH);
                    })
                }
            }),
        ]).catch(error => {            
            manualChargeDetailVM.onHideLoading();
            setInitial(false);            
            localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH);
        });
        
        
    }, [manualChargeDetailVM, manualChargeHeaderVM, setManualChargeDetailState])

    useEffect(() => {
        const onManualChargeDetailRedirect = async(e:any) => {
            switch (e.detail.action) {
                case E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH:                    
                    const manSysCharge = (e.detail.data.data as ManualSystemChargeEntity);
                    localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH, JSON.stringify(manSysCharge));
                    setInitial(true);

                    break;
            }
        }

        customEventListener(E_Type_Of_Event.MANUAL_CHARGE_DETAIL_REDIRECT_EVENT_INITIAL, onManualChargeDetailRedirect);
    }, [manualChargeDetailVM, manualChargeHeaderVM, setManualChargeDetailState]);

    useEffect(() => {
        const manSysCharge = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH)??"") as ManualSystemChargeEntity :
            null;
        
        if (!manSysCharge && manChgHdrState?.currentManualChargeHeader) {
            initialScreen().then(()=>{
                manualChargeDetailVM.onInitDefaultValue(true);
            }).then(()=>{
                currentManualChargeHeader?.id && manualChargeDetailVM.onSearch(currentManualChargeHeader.id)
            });
        } else {
            initialRedirectScreen();
        }    
    }, [manualChargeDetailVM, initial, initialRedirectScreen, initialScreen, manChgHdrState?.currentManualChargeHeader, currentManualChargeHeader?.id])

    const memoManualChargeDetailTitleBar = useMemo(() => <ManualChargeDetailTitleBar/>, []);
    const memoManualChargeHeaderFieldFormPanel = useMemo(() => <ManualChargeHeaderFieldFormPanel/>, []);
    const memoManualChargeDetailContent = useMemo(() => <ManualChargeDetailContent/>, []);

    return <>
        <div className="main-comp-wrapper">
            {memoManualChargeDetailTitleBar}
            <SliderPanel
                isOpen={true}
                leftSectionWidth={"25%"}
                rightSectionWidth={"75%"}
                leftChildren={memoManualChargeHeaderFieldFormPanel}
                rightChildren={memoManualChargeDetailContent}
            />
            
            {isShowAttachmentModal &&
                 <div className='im-charge-data-search-confirm-modal-container'>
                    <ViewAttachmentModal/>
                </div>
            }
        </div>
    </>
}

export default memo(ManualChargeDetailMaintenance)