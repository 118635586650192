import { ChargeDetailRepoImpl } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepoImpl";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { ManualChargeDetailVesselVM } from "presentation/viewModel/ManualCharge/ManualChargeDetailVesselVM";
import { useMemo } from "react";

export const useManualChargeDetailVesselVM = () => {
    const [, setManualChargeDetailState] = useManualChargeDetailTracked();
    const manualChargeDetailVesselVM = useMemo(() =>
        ManualChargeDetailVesselVM({
            dispatch: [setManualChargeDetailState],
            chargeDetailRepo: ChargeDetailRepoImpl()
        }), [setManualChargeDetailState])

    return manualChargeDetailVesselVM
}