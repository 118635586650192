import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { useManualChargeDetailVesselVM } from "presentation/hook/ManualCharge/useManualChargeDetailVesselVM";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { memo, useState } from "react";
import { DialogModal, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import ManualChargeVesselContent from "./ManualChargeVesselContent";

const ManualChargeVesselComp: React.FC = () => {
    const [manualChargeDetailState] = useManualChargeDetailTracked();
    const manualChargeDetailVesselVM = useManualChargeDetailVesselVM();
    const { isShowFindVesselPanel } = manualChargeDetailState;
    const [isLoading,] = useState(false);

    /*useEffect(() => {
        setIsLoading(true);
        manualChargeDetailVesselVM.initSearch(manualChargeDetailState.vesselCntrNos).then((data) => {
            setIsLoading(false);
        })
    }, [ manualChargeDetailVesselVM, manualChargeDetailState.vesselCntrNos])*/

    const handleCloseDialog = () => {
        manualChargeDetailVesselVM.closeCntrCompDialog();
    }

    if (isLoading) return <Loader Indicator="Spinner" size="Medium" />;

    return <DialogModal style={{ minWidth: '62vw', minHeight: '80vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowFindVesselPanel}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{ManualChargeConstant.Detail.CO_VSL_VOY}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={handleCloseDialog} tooltipDisable={true} />
            </div>
        }
        dialogContent={<ManualChargeVesselContent />}
        className="im-charge-data-search-confirm-modal"
    />
}

export default memo(ManualChargeVesselComp);